import coordtransform from "coordtransform";
// 计算距离
function rad(d) {
  return (d * Math.PI) / 180.0;
}
// 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
function calcDistance(lat1, lng1, lat2, lng2) {
  var radLat1 = rad(lat1);
  var radLat2 = rad(lat2);
  var a = radLat1 - radLat2;
  var b = rad(lng1) - rad(lng2);
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    );
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000; //输出为公里

  var distance = s;
  var unit = "";
  if (parseInt(distance) >= 1) {
    distance = distance.toFixed(1);
    unit = "km";
  } else {
    distance = parseInt(distance * 1000);
    unit = "m";
  }
  return {
    unit,
    num: distance,
    text: distance + unit,
  };
}

function coordtransforTo(type, lnglat) {
  switch (type) {
    //百度经纬度坐标转国测局坐标
    case "bd09togcj02":
      return coordtransform.bd09togcj02(lnglat[0], lnglat[1]);
    //国测局坐标转百度经纬度坐标
    case "gcj02tobd09":
      return coordtransform.gcj02tobd09(lnglat[0], lnglat[1]);
    //wgs84转国测局坐标
    case "wgs84togcj02":
      return coordtransform.wgs84togcj02(lnglat[0], lnglat[1]);
    //国测局坐标转wgs84坐标
    case "gcj02towgs84":
      return coordtransform.gcj02towgs84(lnglat[0], lnglat[1]);
    default:
      return lnglat;
  }
}

// 如果是高德地图坐标， 转换为wgs84
function gcj02towgs84(data) {
  const wgsPoint = coordtransform.gcj02towgs84(data.lng, data.lat);
  return {
    lng: wgsPoint[0],
    lat: wgsPoint[1],
  };
}
// 如果是百度地图坐标， 转换为wgs84
function bd09towgs84(data) {
  const amapPoint = coordtransform.bd09togcj02(data.lng, data.lat);
  const wgsPoint = coordtransform.gcj02towgs84(amapPoint[0], amapPoint[1]);
  return {
    lng: wgsPoint[0],
    lat: wgsPoint[1],
  };
}

// wgs84togcjo2
function wgs84togcj02(lng, lat) {
  const amapPoint = coordtransform.wgs84togcj02(lng, lat);
  return amapPoint;
}
//校验手机号
function codeVerification(phone) {
  var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
  // var message = '';
  if (!reg.test(phone)) {
    // message = '请输入正确的手机号码'
    return false;
  }
  return true;
}

//校验身份证
function idCardVerification(idCard) {
  var reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (!reg.test(idCard)) {
    return false;
  }
  return true;
}

export { calcDistance, codeVerification, idCardVerification, coordtransforTo };
