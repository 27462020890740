<template>
  <div class="home">
    <!--创建地图容器-->
    <div id="map-container" class="allmap" :style="{ height: mapHeight }"></div>
  </div>
</template>

<script>
import { BMPGL } from "@/utils/bmpgl.js";
import { mapState } from "vuex";
import { coordtransforTo } from "@/utils/utils.js";
export default {
  name: "Bmap",
  props: {
    height: {
      type: Number,
      String,
      default: 346,
    },
    center: {
      type: Object,
      default: () => {
        return {
          lng: 108.483724,
          lat: 22.726119,
          //提示为默认，设置中心点但不叠加点位，新数据进来会被覆盖
          isDefault: true,
        };
      },
    },
    zoom: {
      type: [Number, String],
      default: 14,
    },
    pic: {
      type: String,
      default: "logo.png",
    },
    locateName: {
      type: String,
      default: "暂无信息",
    },
    type: {
      type: String,
      default: "",
    },
    markers: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    mapData() {
      // let locateName = this.locateName
      // this.initMap()
      return {
        center: this.center,
        zoom: parseInt(this.zoom),
        pic: this.pic,
        locateName: this.locateName,
      };
    },
    mapHeight() {
      return this.height + "px";
    },
    ...mapState({
      settingLang: (state) => state.lang,
      Setting: (state) => state.langSetting.HomeSetting,
    }),
  },
  watch: {
    markers(val) {
      this.$nextTick(() => {
        this.resetMarkers(val);
      });
    },
    locateName() {
      this.initMap();
    },
  },
  data() {
    return {
      ak: "Gd41xd9RtVgmF7VM8fjVe3PVt54DRV1d", // 百度的地图密钥
      myMap: null,
      map: {},
      BMapGL: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  methods: {
    initMap() {
      let self = this;
      // 传入密钥获取地图回调。
      BMPGL(this.ak)
        .then((BMapGL) => {
          self.BMapGL = BMapGL;
          // 创建地图实例
          let map = new BMapGL.Map("map-container");
          // 创建点坐标 axios => res 获取的初始化定位坐标
          let center = coordtransforTo("gcj02tobd09", [
            this.center.lng,
            this.center.lat,
          ]);
          let point = new BMapGL.Point(center[0], center[1]);
          // 初始化地图，设置中心点坐标和地图级别
          map.centerAndZoom(point, this.zoom);
          //开启鼠标滚轮缩放
          map.enableScrollWheelZoom(true);
          // map.setHeading(64.5)
          // map.setTilt(73)
          // 保存数据
          // this.myMap = map

          let contentPic = this.pic;
          let contentName = this.locateName;
          let locateName = this.locateName;
          // console.log(markers);

          this.map = map;
          console.log(this.map);
          if (this.type === "markers" && this.markers.length > 0) {
            this.resetMarkers(this.markers);
          } else {
            setCenter();
          }
          this.imgOverlay();

          function setCenter() {
            let marker = new BMapGL.Marker(point);
            let infoWindow = null;
            if (center.isDefault) return;
            // console.log(121212,point)
            map.addOverlay(marker);
            // if (locateName == "暂无信息") return;
            //   var sContent =
            //     `
            // <img class='img-fit' style='float:left;margin:0 4px 0.6rem' src='` +
            //     contentPic +
            //     `' width='80' height='60'/>
            // <div class='flex align-center justify-center' style='height:100%'>
            //   <p style='font-size:0.9rem'>` +
            //     contentName +
            //     `</p>
            // </div>`;
            // var opts = {
            // width : 200,     // 信息窗口宽度
            // height: 60,     // 信息窗口高度
            // title : contentName , // 信息窗口标题
            // message:"这里是故宫"
            // };
            // infoWindow = new BMapGL.InfoWindow(sContent, opts); // 创建信息窗口对象
            // map.openInfoWindow(infoWindow, point); //开启信息窗口
            // marker.addEventListener("click", function () {
            //   map.openInfoWindow(infoWindow, point);
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetMarkers(markers) {
      let self = this;
      this.map.clearOverlays();
      let BMapGL = this.BMapGL;
      let myIcon = null;
      let more = this.Setting.More;
      let markerId = null;

      // console.log(111,markers.get())
      markers.map((ma) => {
        // console.log(ma)
        ma.markerId = "map_scenic_" + ma.id;
        ma.description ? 0 : (ma.description = this.Setting.NoDescription);

        myIcon = new BMapGL.Icon(ma.iconUrl, new BMapGL.Size(32, 35));
        let lnglat = coordtransforTo("gcj02tobd09", [ma.lng, ma.lat]);
        ma.markerPoint = new BMapGL.Point(lnglat[0], lnglat[1]);
        ma.mark = new BMapGL.Marker(ma.markerPoint, {
          icon: myIcon,
        });

        // console.log(ma.mark)

        if (ma.type === "scenic_spot") {
          ma.sContent =
            `<img class='img-fit' style='float:left;margin:0 0.6rem 0.6rem' src='` +
            ma.pic +
            `' width='80' height='60'/>
            <div class='flex flex-direction align-start' style='height:100%;padding:0 10px 10px 0;'>
              <div class='line-2' style='font-size:1rem;max-width:250px'>` +
            ma.description +
            `</div>
              <a style='margin-left:auto;font-size:0.8rem;' id='` +
            ma.markerId +
            `'>` +
            more +
            `</a>
            </div>`;
        } else {
          ma.sContent =
            `<img class='img-fit' style='float:left;margin:0 4px 0.6rem' src='` +
            ma.pic +
            `' width='80' height='60'/>
            <div class='flex flex-direction align-center justify-center' style='height:100%;'>
              <div class='line-2' style='font-size:1rem;max-width:250px'>` +
            ma.description +
            `</div>
            </div>`;
        }
        var opts = {
          // width : 200,     // 信息窗口宽度
          // height: 100,     // 信息窗口高度
          title: ma.title, // 信息窗口标题
          // message:"这里是故宫"
        };
        ma.infoWindow = new BMapGL.InfoWindow(ma.sContent, opts); // 创建信息窗口对象
        ma.mark.addEventListener("click", function () {
          this.map.openInfoWindow(ma.infoWindow, ma.markerPoint);
        });

        var label = new BMapGL.Label(ma.title, {
          // 创建文本标注
          position: ma.markerPoint, // 设置标注的地理位置
          offset: new BMapGL.Size(0, -55), // 设置标注的偏移量
        });
        this.map.addOverlay(label);
        label.setStyle({
          // 设置label的样式
          color: "#693721",
          fontSize: "1rem",
          background: "rgba(255,255,255,0.83)",
          border: "0",
          padding: "0.1rem 0.5rem",
          borderRadius: "8px",
          // marginLeft: '-40px',
          transform: "translateX(-50%)",
        });

        this.map.addOverlay(ma.mark);

        //添加窗口内点击监听

        if (ma.type !== "scenic_spot") return;
        if (!ma.infoWindow.isOpen()) {
          //如果没有打开，则监听打开事件，获取按钮，添加事件
          ma.infoWindow.addEventListener("open", function () {
            document
              .getElementById(ma.markerId)
              .addEventListener("click", function () {
                self.goDetail(ma.id);
              });
          });
        } else {
          //如果没有打开，则监听打开事件，获取按钮，添加事件
          document.getElementById(markerId).onclick = function (e) {};
        }
      });
    },
    toCenter(center) {
      let p = coordtransforTo("gcj02tobd09", [center.lng, center.lat]);
      console.log(p);
      this.map.setCenter(new this.BMapGL.Point(p[0], p[1]));
    },
    imgOverlay() {
      let p = coordtransforTo("gcj02tobd09", [121.418457, 29.65666]);
      let e = coordtransforTo("gcj02tobd09", [121.460886, 29.676957]);
      const pStart = new this.BMapGL.Point(p[0], p[1]);
      const pEnd = new this.BMapGL.Point(e[0], e[1]);
      const bounds = new this.BMapGL.Bounds(
        new this.BMapGL.Point(pStart.lng, pEnd.lat),
        new this.BMapGL.Point(pEnd.lng, pStart.lat)
      );
      const imgOverlay = new this.BMapGL.GroundOverlay(bounds, {
        type: "image",
        url: require("@/assets/quanjingtu.png"),
        opacity: 1,
      });
      this.map.addOverlay(imgOverlay);
    },
    goDetail(id) {
      const path = "scenicDetail";
      let urlParams = {
        name: path,
        query: {
          id: id,
        },
      };
      this.$router.push(urlParams);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  // padding-bottom: 2rem;
  background: #fff;
}
.allmap {
  width: 100%;
  height: 346px;
  position: relative;
  z-index: 1;
}
</style>
