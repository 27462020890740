<template>
  <div class="image" :style="{ paddingTop: height }">
    <img
      :src="src"
      class="card-img img-fit"
      :class="active_class"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    imgList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: String,
      default: "100%",
    },
    src: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    hasAnimated: {
      type: Boolean,
      default: true,
    },
    animateType: {
      type: String,
      default: "expand", //expand tilt
    },
  },
  computed:{
    active_class(){
      return this.hasAnimated&&this.active ? [this.animateType] : []
    }
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.image {
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 100%;
  overflow: hidden;
}
.card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out; /* IE 9 */
  -moz-transition: all 0.5s ease-in-out; /* Firefox */
  -webkit-transition: all 0.5s ease-in-out; /* Safari 和 Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
}
//放大
.expand {
  transform: scale(1.1);
  -ms-transform: scale(1.1); /* IE 9 */
  -moz-transform: scale(1.1); /* Firefox */
  -webkit-transform: scale(1.1); /* Safari 和 Chrome */
  -o-transform: scale(1.1); /* Opera */
}
//倾斜
.tilt {
  transform: rotate(10deg) scale(1.25);
  -ms-transform: rotate(10deg) scale(1.25); /* IE 9 */
  -moz-transform: rotate(10deg) scale(1.25); /* Firefox */
  -webkit-transform: rotate(10deg) scale(1.25); /* Safari 和 Chrome */
  -o-transform: rotate(10deg) scale(1.25); /* Opera */
}
</style>
