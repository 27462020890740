<template>
  <div class="bread flex align-center justify-start">
    <div>{{ BreadSetting.locate }}</div>
    <a-breadcrumb :routes="routes" separator=">">
      <template #itemRender="{ route, routes, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span>
        <router-link v-else :to="`/${paths[paths.length - 1]}`">
          {{ route.breadcrumbName }}
        </router-link>
      </template>
    </a-breadcrumb>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "bread",
  data() {
    return {
      setting: {},
    };
  },
  computed: {
    ...mapState({
      BreadSetting: (state) => state.langSetting.BreadSetting,
      routes: (state) => state.bread,
    }),
    // routes(){
    //   //从session中取面包屑，防止刷新消失
    //   if(!(this.bread&&this.bread.length>0)){
    //     let bread = JSON.parse(sessionStorage.getItem('bread')) || []
    //     this.$store.commit('changeBread', bread);
    //     return bread
    //   }else{
    //     return this.bread
    //   }
    // }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.bread {
  height: 53px;
  width: 100%;
  background: #ffffff;
  padding: 0 18.75%;
  font-size: 14px;
  // font-family: PingFang SC;
  font-weight: 400;
  color: #212121;
}
</style>
