<template>
  <div class="page">
    <!-- 页面头部 包含面包屑 -->
    <PageHead />

    <div class="page-body">
      <div class="card-detail">
        <div class="detail-head flex align-end" v-if="settingLang === 'zh'">
          <img src="images/detail-leftTop-bg.png" class="leftTop-bg" />
          <div class="head-name">{{ leftTopTitle.zh }}</div>
          <div class="head-name-en">
            {{ leftTopTitle.en }}
          </div>
        </div>
        <div class="detail-head flex align-end" v-else>
          <img src="images/detail-leftTop-bg.png" class="leftTop-bg" />
          <div class="head-name">
            {{ leftTopTitle.en }}
          </div>
        </div>
        <div class="detail-body">
          <div class="html-info text-center">
            <span>{{ news_detail.title }}</span>
          </div>
          <div
            class="shuffling flex justify-center"
            v-if="news_detail.pics && news_detail.pics.length > 0"
          >
            <div class="swiper">
              <swiper
                :slides-per-view="1"
                :space-between="0"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
                :pagination="{
                  type: 'fraction',
                  el: '.raiders-swiper-pagination',
                }"
              >
                <swiper-slide
                  v-for="(pic, index) in news_detail.pics"
                  :key="index"
                >
                  <a-image
                    width="100%"
                    :src="pic.url"
                    :preview="false"
                    class="img-fit"
                  />
                </swiper-slide>
                <div class="raiders-swiper-pagination"></div>
              </swiper>
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>
          </div>
          <div class="center-container flex justify-around">
            <div class="center-box flex justify-between">
              <div style="width: 68px">
                <PictureSquare src="icons/icon-detail-phone.png" />
              </div>
              <div class="right flex flex-direction" style="flex: 1">
                <div>{{ DetailSetting.raiderPhone }}</div>
                <div class="right-label">
                  {{ news_detail.telephone || DetailSetting.raiderPhoneNone }}
                </div>
              </div>
            </div>
            <div class="center-box flex justify-center">
              <div style="width: 68px">
                <PictureSquare src="icons/icon-detail-map.png" />
              </div>
              <div class="right flex flex-direction" style="flex: 1">
                <div>{{ DetailSetting.raiderAddress }}</div>
                <div class="right-label">
                  {{ news_detail.address || DetailSetting.raiderAddressNone }}
                </div>
              </div>
              <!-- {{news_detail.address||'暂无地址'}} -->
            </div>
            <div class="center-box flex justify-center">
              <div style="width: 68px">
                <PictureSquare src="icons/icon-detail-price.png" />
              </div>
              <div class="right flex flex-direction" style="flex: 1">
                <div>{{ DetailSetting.raiderPrice }}</div>
                <div class="right-label">
                  {{ news_detail.price || DetailSetting.raiderPriceNone }}
                </div>
              </div>
            </div>
          </div>
          <div class="html-des-label">{{ DetailSetting.description }}</div>
          <div class="html-content" v-html="news_detail.content"></div>
          <Map
            v-if="map_data.center"
            :center="map_data.center"
            :zoom="map_data.zoom"
            :pic="map_data.pic"
            :locateName="map_data.locateName"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHead from "@/components/page-header/page-head";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Navigation, Autoplay, Pagination]);
import PictureSquare from "@/components/browse/picture-square";
import { mapState } from "vuex";
import Map from "@/components/bmap/bmap";
export default {
  name: "scenicDetail",
  components: {
    PageHead,
    Swiper,
    SwiperSlide,
    PictureSquare,
    Map,
  },
  computed: {
    ...mapState({
      settingLang: (state) => state.lang,
      DetailSetting: (state) => state.langSetting.DetailSetting,
      bread: (state) => state.bread,
    }),
    leftTopTitle() {
      return this.bread[this.bread.length - 1];
    },
  },
  created() {
    this.initData(this.$route.query.id);
  },
  data() {
    return {
      title: "攻略详情",
      titleEn: "Raider Detail",
      exampleImg: process.env.BASE_URL + "images/page-head/raiders.png",
      subTitle: "八桂神韵 绿色乐意",
      subTitleEn: "八桂神韵 绿色乐意",
      pageParams: {},
      news_detail: {},
      map_data: {},
    };
  },
  methods: {
    async initData(id) {
      let news_detail = await this.$http.Hotel_read({
        id: id,
      });
      let pics = news_detail.hotel_files.filter((item) => item.type == "image");
      if (pics.length > 0) {
        news_detail.pics = pics;
      }

      news_detail.price = news_detail.ave_price + "起" || null;
      news_detail.title = news_detail.hotel_name;
      this.news_detail = news_detail;
      this.map_data = {
        pic:
          news_detail.pic && news_detail.pic.length > 0
            ? news_detail.pic[0]
            : "",
        locateName: news_detail.address,
      };
      if (news_detail.map_lat && news_detail.map_lng) {
        this.map_data.center = {
          lat: news_detail.map_lat,
          lng: news_detail.map_lng,
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  font-weight: 400;
  background: #f5f5f5;
}
.page-body {
  padding: 2.5rem 18.75%;
  .detail-head {
    // height: 120px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #aaaaaa;
    margin-bottom: 1rem;
    position: relative;
    .leftTop-bg {
      position: absolute;
      left: 5%;
      bottom: 0;
      height: 150%;
    }
    .head-name {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #212121;
      margin-right: 0.6rem;
    }
    .head-name-en {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
  .detail-body {
    background: #fff;
    padding: 1rem 2%;
    .html-info {
      padding: 0.5rem 0;
      background: #f5f5f5;
      font-size: 1.8rem;
      font-family: Microsoft YaHei;
      color: #212121;
    }
    .html-des-label {
      padding: 1rem 0 0.5rem 0;
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
    }
    .html-content {
      // min-height: 300px;
      padding-bottom: 1rem;
      /deep/img {
        max-width: 100%;
      }
    }
    .center-container {
      margin-top: 0.8rem;
      border: 6px solid #eeeeee;
      padding: 2.5rem 5%;

      .center-box {
        // flex: 1;
        padding: 0 1%;
        .right {
          font-size: 1.5rem;
          font-family: Microsoft YaHei;
          color: #666666;
          margin-left: 0.5rem;
        }
        .right-label {
          color: #aaa;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.shuffling {
  // height: 816px;
  width: 100%;
  max-height: 433px;
  position: relative;
}
.swiper {
  width: 100%;

  & /deep/ .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background-color: #f29b76;
      }
    }
  }
  .swiper-button {
    width: 45px;
    height: 45px;
    background: rgba(27, 27, 27, 0.7);
    border-radius: 50%;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    color: #fff;
    // margin-top: -2rem;
    z-index: 3;
    padding: 10px 0;
  }
  .swiper-button-prev:after {
    content: "prev";
    font-size: 20px;
  }
  .swiper-button-next:after {
    content: "next";
    font-size: 20px;
  }
  .raiders-swiper-pagination {
    // height: 70px;
    margin-top: -45px;
    position: relative;
    z-index: 3;
    padding: 0 0.5rem;
    color: #fff;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
  }
}
</style>
