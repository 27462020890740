<template>
  <div>
    <div class="head-img">
      <img :src="headSetting.img" class="background-image img-fit" />
      <div class="info-box flex flex-direction align-center justify-center">
        <span class="info-box-title">{{ pageTitle }}</span>
        <!-- <span class="info-box-line"></span> -->
        <span class="info-box-des line-1" :title="headSetting.subtitle">{{
          headSetting.subtitle
        }}</span>
      </div>
    </div>
    <Bread />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Bread from "@/components/bread/bread";
export default {
  name: "page-head",
  components: {
    Bread,
  },
  props: {
    headImg: {
      type: String,
      default: process.env.BASE_URL + "images/overview.png",
    },
    headTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      settingLang: (state) => state.lang,
      bread: (state) => state.bread,
    }),
    headSetting() {
      let bread = this.getBread();
      let index = bread.length - 1;
      let setting = {
        subtitle: "",
        img: "images/page-head/scenic.png",
      };

      if (index >= 0) {
        if (bread[index].headTitle) {
          setting.subtitle = bread[index].headTitle.title;
        } else if (index - 1 >= 0 && bread[index - 1].headTitle) {
          //取父级菜单的配置
          setting.subtitle = bread[index - 1].headTitle.title;
        }
        if (bread[index].headImg) {
          setting.img = "images/page-head/" + bread[index].headImg + ".png";
        } else if (index - 1 >= 0 && bread[index - 1].headImg) {
          setting.img = "images/page-head/" + bread[index - 1].headImg + ".png";
        }
      }

      return setting;
    },
    pageTitle() {
      let bread = this.getBread();
      return bread[bread.length - 1].breadcrumbName;
    },
  },
  mounted() {},
  methods: {
    //刷新拿缓存
    getBread() {
      let bread = null;
      if (!(this.bread && this.bread.length > 0)) {
        bread = JSON.parse(sessionStorage.getItem("bread")) || [];
      } else {
        bread = this.bread;
      }
      return bread;
    },
  },
};
</script>

<style lang="less" scoped>
.head-img {
  height: 480px;
  width: 100%;
  position: relative;
  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: inherit;
  }
  .info-box {
    width: 100%;
    height: 100%;
    position: absolute;
    color: #ffffff;
    z-index: 2;
    .info-box-title {
      font-size: 70px;
      letter-spacing: 8px;
      font-weight: bold;
      margin-top: 80px;
      // font-family: FZZhengHeiS-EB-GB;
      // line-height: 26px;
      text-shadow: 0px 1px 5px rgba(27, 27, 27, 0.84);
    }
    .info-box-line {
      width: 55px;
      // height: 5px;
      border: 3px solid #ffffff;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }
    .info-box-des {
      margin-top: 2rem;
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      width: 60%;
      text-align: center;
    }
  }
}
</style>
